@charset "utf-8";

$link-color: #2855dd;
$shadow-distance: 0px;
$shadow-color: gray;
$article-color: white;
$header-footer-color: #eee;
$nav-hover-color: #ccc;

* {
    box-sizing: border-box;
}

html, body {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
}

body {
    #container {
        min-height: 100%;
        position: relative;
    }
}

main {
    margin: auto;
    width: 80%;
    padding-bottom: 60px;
}

body {
    a:link {
        text-decoration: none;
        color: $link-color;
        box-shadow: 0px 0px ;
    }

    a:visited {
        color: scale-color($link-color, $saturation: -20%);
    }

    a:hover {
        color: scale-color($link-color, $saturation: 20%, $lightness: -20%);
    }

    a:active {
        color: scale-color($link-color, $saturation: 30%, $lightness: -50%);
    }

    a.button {
        border: 1px solid;
        border-radius: 3px;
        padding: 6px 14px 3px;
        text-align: center;
        display: inline-block;
    }

    a.buttonPlus {
        border: 1px solid;
        border-radius: 3px;
        padding: 0px 14px 5px;
        text-align: center;
    }

    a.button:hover {
        box-shadow: $shadow-distance $shadow-distance 2px $shadow-color;
    }
}

article {
    margin: auto;
    margin-bottom: 50px;
    background-color: $article-color;
    border: 1px solid;
    border-color: scale-color($shadow-color,  $lightness: +25%);
    border-radius: 10px;
    padding: 50px;
    box-shadow: $shadow-distance $shadow-distance 4px $shadow-color;
}

article.mainContent {
    background-color: scale-color($article-color, $lightness: -5%);
}

section {
    margin: 0 auto;
    width: 100%;
    height: 100%;
}
section.text, section.poem {
    width: auto;
    display: table; // center text block
    p {
        text-indent: 0;
    }
}
section.screenplay {
    background-color: white;
    padding: 40px;
    box-shadow: 5px 5px 8px $shadow-color;
}

h1 {
    text-align: center;
}

h1 { padding: 30px; }

/* Nav bar */
nav {
    background-color: $header-footer-color;
    top: 0px;
    position: sticky;
    z-index: 1;

    display: flex;
    width: 100%;
    min-height: 50px;
    margin: 0;
    padding: 0;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    box-shadow: $shadow-distance $shadow-distance 4px $shadow-color;

    >ul {
        display: flex;
        flex-flow: row wrap;
        list-style-type: none;
        margin: 0;
        padding: 0;
        text-align: center;
        justify-content: space-between;
        flex-grow: 1;

        li {
            flex-grow: 1;
        }
    }

    a {
        display: block;
        padding: 14px 10px;
        text-decoration: none;
    }
    img {
        display: inline-block;
        margin: 0;
    }
    a:hover {
        background-color: $nav-hover-color;
    }
    .menuButton {
        display: none;
        height: 100%;
    }

    #siteTitle {
        order: 0;
        flex-grow: 0.2;
    }
    .translation {
        flex-grow: 0.2;
    }
    #siteTitle, .translation, .translation:visited{
        color: black;
    }

    #searchContainer {
        display: none;
        height: 30px;
        width: 20%;
        min-width: 100px;
        padding: 0 10px;
        input {
            display: inline-block;
            border: 1px solid #bbb;
            border-radius: 3px;
            padding: 7px;
            width: 100%;
        }
        ul {
            background-color: white;
            width: 100%;
            min-width: 150px;
            margin: 0;
            padding: 0;
            position: relative;
            float: right;
            border: 1px solid #bbb;
            border-style: none;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;

            li {
                display: block;
                list-style-type: none;
                border-bottom: 1px solid #bbb;
                margin-right: auto;
                text-align: left;
                color: $link-color;
                a {
                    background-color: initial;
                    display: block;
                    padding: 5px;
                    margin: 0;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            li:last-child {
                border-bottom-style: none;
            }
            li:hover {
                background-color: $header-footer-color;
            }
        }
    }
}

/* Responsive layout - when the screen is less than 760px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 760px) {
    nav {
        .menuButton {
            order: -1;
            font-size: 2em;
            padding: 0px 10px;
            display: inline-block;
        }
        .menuButton:hover {
            /* color: #333; */
            background-color: $nav-hover-color;
            + ul {
                opacity: 1;
                visibility: visible;
            }
        }
        >ul:hover {
            opacity: 1;
            visibility: visible;
        }
        >ul {
            transition: opacity 0.3s;
            opacity: 0;
            visibility: hidden;
            display: flex;
            position: absolute;
            top: 50px;
            left: 50px;
            max-width:80%;
            background-color: $header-footer-color;
            box-shadow: 5px 5px 15px $shadow-color;
            justify-content: space-between;
            li {
                flex-basis: 50%;
            }
            li:first-child {
            }
        }

        .translation {
            order: 2;
            flex-basis: 20%;
            flex-grow: 0;
        }
        #siteTitle {
            flex-basis: 50%;
        }
        .menuButton {
            flex-basis: 20%;
        }
    }
    main {
        width: 100%;
    }
    article {
        border-radius: 0;
        padding-left: 20px;
        padding-right: 20px;
    }
    section {
        max-width: 95%;
    }
}

/* For terminal display */
pre {
    line-height: 100%;
}

ul.tags {
    display: flex;
    flex-flow: row wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;
    justify-content: space-between;
    align-items: center;

    li {
        padding: 10px ;
    }
}
footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 50px;

    background-color: $header-footer-color;
    color: darkgray;
    text-align: center;
    padding: 14px 20px;
}

// Medias
img, video {
    display: block;
    max-width: 100%;
    margin: auto;
    margin-bottom: 30px;
    max-height: 90vh;
}

@media (display-mode: fullscreen) {
    img, video {
        max-height: 100%;
    }
}

img.icon {
    display: inline;
    margin-bottom: 0;
    margin-left: 5px;
    margin-right: 5px;
}

iframe.internal {
    border-style: none;
    width: 100%;
    height: 100vh;
}

//Pager
.pager {
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
}

// Generic classes
.date {
    text-align: right;
}

.right {
    margin-left: auto;
    margin-right: 0;
    text-align: right;
}

.big {
    font-size: 200%;
}

.center {
    text-align: center;
}

// Typography
@font-face {
    font-family: Fanwood;
    src: url(/fonts/Fanwood_Text.otf);
}
@font-face {
    font-family: Fanwood;
    src: url(/fonts/Fanwood_Text_Italic.otf);
    font-style: italic;
}
@font-face {
    font-family: Jost;
    src: url(/fonts/Jost-500-Medium.otf);
}
@font-face {
    font-family: Jost;
    src: url(/fonts/Jost-500-MediumItalic.otf);
    font-style: italic;
}
@font-face {
    font-family: Monofur;
    src: url(/fonts/Monofur-Regular.otf);
}
@font-face {
    font-family: Monofur;
    src: url(/fonts/Monofur-Italic.otf);
    font-style: italic;
}

/* @font-feature-values Jost { */
/*     @styleset { */
/*         ss01: 1; */
/*     } */
/* } */

p {
    text-indent: 1em;
}
section, kbd {
    font-family: Fanwood, serif;
    font-size: 1.3em;
    text-align: justify;
}
kbd {
    font-size: 1em;
    display: inline-block;
    border: 1px solid;
    border-color: #0003;
    border-radius: 3px;
    padding: 1px 3px;
    text-indent: 0;
    }
body, .date, h1, h2, h3, h4, h5, h6, .subtitle {
    font-family: Jost, sans-serif;
    /* font-variant-alternates: styleset(ss01); */
    font-feature-settings: "ss01";
}
h1 {
    font-size: 2.5em;
    font-weight: 500;
}

em em {
    font-style: normal;
}
em em em {
    font-style: italic;
}
pre, code {
    white-space: pre-wrap;
    font-family: Monofur, Courier, monospace, monospace;
}

// Bullet points
section ul:lang(fr):not(.tags) {
    text-align: left;
    list-style-type: none;
    padding: 0;

    li:before {
        display: inline-block;
        text-indent: 1em;
        content: "– ";
    }
}

// Printing
@media print {
    footer, nav {
        display: none;
    }
}

// Fountain screenplay
.screenplay, .screenplay *{
    font-family: Courier, "Courier New", monospace;
    font-size: inherit;
    line-height: 1;
    max-width: 8in;
    margin: 1em auto;
    -webkit-text-size-adjust: none;
}
.screenplay br {
    margin-bottom: 1em;
    margin-top: 0;
}
.screenplay h1, .screenplay h2, .screenplay h3, .screenplay h4, .screenplay h5, .screenplay h6, .screenplay p {
    font-weight: inherit;
    font-size: inherit;
}
section.screenplay a {
    color: black;
    text-decoration: none;
}
.screenplay .underline {
    text-decoration: underline;
}
.screenplay .title-page {
    margin: 0 auto 1em;
}
.screenplay .title-page .title {
    text-align: center;
}
.screenplay .title-page .title h1 {
    text-transform: uppercase;
    text-decoration: underline;
}
.screenplay .section-heading {
    text-align: center;
}
.screenplay .section-heading:hover {
    background-color: lightyellow;
}
.screenplay .scene {
    margin-top: 2em;
}
.screenplay .scene-heading {
    margin-bottom: 0;
}
.screenplay .scene-heading:hover {
    background-color: lightyellow;
}
.screenplay .action {
    margin: 1em 0;
    white-space: pre-wrap;
}
.screenplay .dialog {
    width: 75%;
    max-width: 4in;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 17%;
    text-align: left;
}
.screenplay .dialog .character {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 25%;
}
.screenplay .dialog .lines {
    max-width: 3.5in;
    margin-top: 0;
    margin-bottom: 0;
    white-space: pre-wrap;
}
.screenplay .dialog .paren {
    max-width: 2in;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 15%;
    text-indent: -0.6em;
    page-break-inside: avoid;
    page-break-after: avoid;
}
.screenplay .dual-dialog {
    width: 100%;
    margin: 1em 0;
}
.screenplay .dual-dialog .dialog {
    max-width: 50%;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    float: left;
    clear: none;
}
.screenplay .dual-dialog .dialog .lines {
    width: 95%;
}
.screenplay .trans {
    max-width: 2in;
    margin-left: 63%;
    clear: both;
    page-break-before: avoid;
}
.screenplay .note {
    display: block;
    font-size: 11pt;
    font-family: "Comic Sans MS", "Marker Felt", "sans-serif";
    line-height: 1.5;
    background-color: lightgoldenrodyellow;
    padding: 1em;
}
.screenplay .synopsis {
    margin-top: 0;
    color: grey;
    font-style: italic;
}
.screenplay .center {
    text-align: center;
    white-space: pre-wrap;
}
